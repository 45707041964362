html,
body {
  height: 100%;
}

// Inputs
input.form-control {
  border-radius: 4px;
}

input.form-control:focus {
  border-color: $primary;
}

input.form-control:read-only:focus {
  border-color: #ced4da;
  box-shadow: none;
}

.input-group-text {
  border-radius: 4px;
  background: $light;
}

// Buttons
.btn {
  letter-spacing: 1px;
  border: 0;
  font-size: 0.875rem;
  line-height: 1.2em;
  transition: background-color 0.3s ease-out, color 0.3s ease-out,
    border-color 0.3s ease-out;
  line-height: 1.2em;
  padding: 1.15em;
}

.btn[class*="btn-outline"] {
  border: 1;
  border-style: solid;
  border-width: 2px;
}

.btn i {
  color: inherit;
}

.btn-primary:hover {
  border-color: $secondary;
  background-color: $secondary;
  text-decoration: none;
}

.btn-primary:disabled,
.btn-primary[disabled]:hover {
  border-color: $primary;
  background-color: $primary;
}

.btn.btn-light {
  border: 2px solid $gray-900;
}

// Links
a {
  font-weight: 600;
}

a.external::after {
  font-family: "Font Awesome 5 Free";
  content: "\F35D";
  padding-left: 5px;
}

h1 {
  color: $primary;
}

h3 {
  color: $secondary;
}

hr {
  border: 1px solid $primary;
}

.card {
  margin-bottom: 55px;
  padding: 20px;
  background: $gray-100;
  border-radius: 0px;
  border: 0;
}

i {
  color: $primary;
}

label {
  line-height: 1.8;
}

.navbar {
  padding: 2px 1rem;
}

.navbar-toggler {
  border-radius: 0;
  border: 0;
}

.navbar i {
  font-size: 1.5em;
  color: $white;
}

.navbar img {
  width: 45px;
}

.navbar-secondary {
  background-color: #f9f9f9;
  padding: 0px;
}

.navbar-secondary .navbar-nav {
  padding: 0 5px;
}

.navbar-secondary li a {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  text-decoration: none;
  padding: 15px 10px 12px;
  display: block;
  border-bottom: 3px solid transparent;
  transition: border-bottom 0.15s;
}

.navbar-secondary li a:hover {
  color: #09f;
  border-bottom: 3px solid #09f;
}

.navbar-secondary a.active {
  color: $primary;
  border-bottom: 3px solid $primary;
}

// Custom sidenav
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity 0.05s linear;
}

.sidenav {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0px;
  -ms-flex-preferred-size: 100%;
  width: 260px;
  transition: all 0.25s ease;
}

.sidebar-container {
  width: 260px;
  height: 100%;
  z-index: 30;
  display: block;
  background-color: $light;
  overflow-y: auto;
  min-width: 260px;
}

.sidenav .navbar {
  height: 60px;
  margin: 0 0 15px;
  justify-content: flex-end;
  background-color: #eee;
  width: 100%;
}

.sidenav .navbar-bars i {
  color: $black;
  text-decoration: none;
  font-size: 2em;
}

.sidenav .navbar-bars a {
  padding: 0px;
}

.sidenav .mobilelist {
  list-style-type: none;
  line-height: normal;
  padding: 0px;
}

.sidenav .mobilelist i {
  color: $black;
}

.sidenav a {
  text-decoration: none;
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  display: block;
  color: $black;
}

.sidenav a.mobilelist-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 12px 15px 12px 20px;
  text-decoration: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sidenav a.mobilelist-section span {
  font-size: 16px;
  font-weight: 600;
  color: $black;
}

.sidenav .mobilelist-section.active span {
  color: #46cdff;
}

.sidenav li .active {
  color: $primary;
  border-right: solid $primary;
}

.sidebar li a {
  text-decoration: none;
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  display: block;
  color: black;
}

.sidebar ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  padding-top: 1em;
}

.sidebar-wrapper {
  display: none;
}

@media (min-width: 992px) {
  .sidebar-wrapper {
    display: block;
  }
}

.mobilesublist .group {
  border-bottom: 1px solid #f2f2f2;
  padding: 15px 0 15px 10px;
}

.mobilesublist ul.list {
  margin: 0 0 5px;
  padding: 0;
  list-style: none;
}

// Add collapse from left ability for sidenav
.collapse.show {
  visibility: visible;
}

.collapsing {
  visibility: visible;
  height: 0;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.collapsing.width {
  -webkit-transition-property: width, visibility;
  transition-property: width, visibility;
  width: 0;
  height: 100%;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

ul.dropdown-menu {
  border-radius: 0px;
  left: unset;
  top: unset;
}

.navbar-secondary .dropdown-menu,
.navbar-secondary .dropdown-item {
  border-radius: 0px;
  top: unset;
  left: unset;
}

.navbar-secondary .dropdown-item:hover {
  border-radius: 0px;
}

li.dropdown-item:first-child,
li.dropdown-item:last-child {
  border-radius: 0px;
}

footer {
  background-color: $primary;
  color: #ffffff;
  margin: 0px;
  padding: 1em 0;
}

footer a {
  color: white;
}

footer ul {
  list-style: none;
  text-decoration: none;
  margin: 0;
  text-align: center;
  padding: 0;
  color: white;
}

footer li {
  display: inline;
  padding: 0 12.5px;
  font-size: 14px;
}

footer img {
  height: 45px;
}

.powered-by {
  width: 9.3em;
}

ul {
  line-height: 1.9;
}

// Searchbox
@media (min-width: 568px) {
  .form-inline .form-control.searchbox {
    width: 75%;
  }
}

.text-break {
  white-space: pre;
}

.w-90 {
  width: 90% !important;
}

.mw-90 {
  max-width: 90% !important;
}

.h-90 {
  height: 90% !important;
}

.mh-90 {
  min-height: 90% !important;
}

// Table

.react-bootstrap-table {
  font-size: 14px;
  padding: 6px 10px;
}

.table-bordered tbody tr td,
.table-bordered thead tr th {
  padding: 6px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table:hover {
  cursor: pointer;
}

.table {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 992px) {
  .table {
    overflow: auto;
    display: block;
    table-layout: auto;
  }

  img {
    max-width: 50px;
    max-height: 50px;
  }
}