// Overwrite Bootstrap's variables here
// You can find them in node_modules/bootstrap/scss/_variables.scss
// Copy the variables you need into this file, don't modify files under node_modules/

// Some example variables that you can uncomment:

// Enabling shadows and gradients
//$enable-shadows: true;
//$enable-gradients: true;

// Changing the body background and text
$body-bg: #FFFFFF;
$body-color: #333;


// Changing the theme colors 
$primary: #003DA5;
$secondary: #00A3E0;
$success: #00B140;
$info: #00A3E0;
$warning: #E87722;
$danger: #EF3340;
$light: #FFFFFF;
$dark: #333;


// Font/Typography
$font-family-sans-serif: Montserrat,
sans-serif;
$headings-font-weight: 600;
$hr-border-color: $primary;

// Button
$btn-font-weight: 600;
$border-radius: 40px;

// Link
$link-hover-color: $primary;